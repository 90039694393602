export const dataBook = {
  1: [[{ imgUrl: "img/FriendsPlus/ListPage/1.jpg", excerciseNum: 0 }]],
  2: [[{ imgUrl: "img/FriendsPlus/ListPage/2.jpg", excerciseNum: 0 }]],
  3: [[{ imgUrl: "img/FriendsPlus/ListPage/3.jpg", excerciseNum: 0 }]],
  4: [
    [{ imgUrl: "img/FriendsPlus/Page56/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page56/2.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page56/3.jpg", excerciseNum: 4 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page56/4.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page56/5.jpg", excerciseNum: 5 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page56/6.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page56/7.jpg", excerciseNum: 6 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page56/8.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page56/9.jpg", excerciseNum: 6 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page56/10.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page56/11.jpg", excerciseNum: 3 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page56/12.jpg", excerciseNum: 0 }],
  ],
  5: [
    [{ imgUrl: "img/FriendsPlus/Page57/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page57/2.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page57/3.jpg", excerciseNum: 4 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page57/4.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page57/5.jpg", excerciseNum: 4 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page57/6.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page57/7.jpg", excerciseNum: 5 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page57/8.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page57/9.jpg", excerciseNum: 5 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page57/10.jpg", excerciseNum: 0 }],
  ],
  6: [
    [{ imgUrl: "img/FriendsPlus/Page58/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page58/2.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page58/3.jpg", excerciseNum: 4 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page58/4.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page58/5.jpg", excerciseNum: 5 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page58/6.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page58/7.jpg", excerciseNum: 6 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page58/8.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page58/9.jpg", excerciseNum: 6 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page58/10.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page58/11.jpg", excerciseNum: 6 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page58/12.jpg", excerciseNum: 0 }],
  ],
  7: [
    [{ imgUrl: "img/FriendsPlus/Page59/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page59/2.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page59/3.jpg", excerciseNum: 4 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page59/4.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page59/5.jpg", excerciseNum: 5 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page59/6.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page59/7.jpg", excerciseNum: 5 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page59/8.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page59/9.jpg", excerciseNum: 6 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page59/10.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page59/11.jpg", excerciseNum: 6 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page59/12.jpg", excerciseNum: 0 }],
  ],
  8: [
    [{ imgUrl: "img/FriendsPlus/Page60/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page60/2.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page60/3.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page60/4.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page60/5.jpg", excerciseNum: 3 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page60/6.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page60/7.jpg", excerciseNum: 3 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page60/8.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page60/9.jpg", excerciseNum: 4 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page60/10.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page60/11.jpg", excerciseNum: 5 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page60/12.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page60/13.jpg", excerciseNum: 6 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page60/14.jpg", excerciseNum: 0 }],
  ],
  9: [
    [{ imgUrl: "img/FriendsPlus/Page61/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page61/2.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page61/3.jpg", excerciseNum: 3 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page61/4.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page61/5.jpg", excerciseNum: 3 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page61/6.jpg", excerciseNum: 0 }],
  ],
  10: [
    [{ imgUrl: "img/FriendsPlus/Page62/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page62/2.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page62/3.jpg", excerciseNum: 4 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page62/4.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page62/5.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page62/6.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page62/7.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page62/8.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page62/9.jpg", excerciseNum: 5 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page62/10.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page62/11.jpg", excerciseNum: 5 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page62/12.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page62/13.jpg", excerciseNum: 6 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page62/14.jpg", excerciseNum: 0 }],
  ],
  11: [
    [{ imgUrl: "img/FriendsPlus/Page63/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page63/2.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page63/3.jpg", excerciseNum: 2 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page63/4.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page63/5.jpg", excerciseNum: 2 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page63/6.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page63/7.jpg", excerciseNum: 3 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page63/8.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page63/9.jpg", excerciseNum: 4 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page63/10.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page63/11.jpg", excerciseNum: 5 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page63/12.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page63/13.jpg", excerciseNum: 6 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page63/14.jpg", excerciseNum: 0 }],
  ],
};
