import p56 from "./56";
import p57 from "./57";
import p58 from "./58";
import p59 from "./59";
import p60 from "./60";
import p61 from "./61";
import p62 from "./62";
import p63 from "./63";

const pageQuestions = {
  4: p56,
  5: p57,
  6: p58,
  7: p59,
  8: p60,
  9: p61,
  10: p62,
  11: p63,

  // 82: p82,
  // 83: p83,
  // 84: p84,
  // 85: p85,
  // 86: p86,
  // 87: p87,
  // 88: p88,
  // 89: p89,
  // 90: p90,
  // 91: p91,
  // 92: p92,
  // 93: p93,
  // 94: p94,
  // 95: p95,
  // 96: p96,
  // 97: p97,
  // 98: p98,
  // 99: p99,
  // 100: p100,
  // 101: p101,
  // 102: p102,
  // 103: p103,
};

export default pageQuestions;
